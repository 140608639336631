'use client'

import { Popover, Transition } from '@headlessui/react'
import { clsx } from 'clsx'
import React from 'react'
import { Link } from '~/components/ui/link'

function MobileNavLink({ href, children }: { href: string; children: React.ReactNode }) {
  return (
    <Popover.Button as={Link} href={href} className='block w-full p-2'>
      {children}
    </Popover.Button>
  )
}

export function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className='relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none'
        aria-label='Toggle Navigation'
      >
        {({ open }) => (
          <svg
            aria-hidden='true'
            className='h-3.5 w-3.5 overflow-visible stroke-slate-700'
            fill='none'
            strokeWidth={2}
            strokeLinecap='round'
          >
            <path
              d='M0 1H14M0 7H14M0 13H14'
              className={clsx('origin-center transition', open && 'scale-90 opacity-0')}
            />
            <path
              d='M2 2L12 12M12 2L2 12'
              className={clsx('origin-center transition', !open && 'scale-90 opacity-0')}
            />
          </svg>
        )}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={React.Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='duration-150 ease-in'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Popover.Overlay className='fixed inset-0 bg-slate-300/50' />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            as='div'
            className='absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg text-slate-900 tracking-tight shadow-xl ring-1 ring-slate-900/5'
          >
            <MobileNavLink href='/#features'>기능 소개</MobileNavLink>
            {/*<MobileNavLink href='/#testimonials'>후기</MobileNavLink>*/}
            <MobileNavLink href='/#pricing'>가격정책</MobileNavLink>
            <MobileNavLink href='/blog'>블로그</MobileNavLink>
            <hr className='m-2 border-slate-300/40' />
            <MobileNavLink href='/app'>로그인</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}
